export const ContactData = [
    {
        id: 0,
        text1: 'PAKISTAN: 18/A, South Park Avenue DHA Phase 2 Ext, Karachi, Pakistan.', 
        text2: 'USA: 1309 Coffeen Avenue STE, Sheridan, WY, 82801, USA.',
        text3: 'Czechia: Prague',
        text4: 'Cayman Islands'
    },
    {
        id: 1,
        text1: 'Pakistan: +92-333-2141881',
        text2: 'UAE: + 971 - 54 - 445 - 3998',
        text3: 'USA: +1 - 3076 - 456 - 4569',
        text4: 'UK: +44 - 779 - 917 - 8855',
    },
    {
        id:2,
        text1:'Email: info@algotechpro.com',
    }
]